:root {
  --bb-bg: rgb(14, 43, 5);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  min-height: 100vh;

  background-color: black;
}

.navbar {
  width: 100%;
  height: 80px;
  background-color: var(--bb-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .links {
  font-size: 25px;
  color: white;
}
.links a {
  margin: 10px;
  text-decoration: none;
  color: white;
}

.links a:hover {
  cursor: pointer;
  font-weight: bold;

  text-decoration: none;
}

/* .SeasonPage,
.EpisodePage,
.CharacterPage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh -80px);
} */

.SeasonsContainer,
.EpisodesContainer,
.CharactersContainer {
  position: relative;
  height: calc(100vh -80px);
  max-width: 80%;
  width: 60vw;
  display: flex;
  justify-content: center;
  flex-direction: column;

  color: white;
  background-color: var(--bb-bg);
  box-shadow: 2px 1px 8px white;

  padding: 30px;
  margin: 0 auto;
  margin-top: 50px;
}

.Season,
.Episode,
.Character {
  width: 100%;

  display: flex;
  flex-direction: column;

  /* border: 1px solid white;
  box-shadow: 2px 1px 8px white; */
}

.Season img {
  cursor: unset;
}

.episode-title {
  width: 100%;
  padding-top: 1em;
  text-transform: capitalize;
}

.character-title {
  width: 100%;
  padding-top: 1em;
  text-transform: capitalize;
}

h5.episode-title {
  padding-bottom: 0.5em;
  margin-left: 1em;
}
p.episode-title {
  padding: 0.25em 0;
  margin-left: 1em;
}

h5.character-title {
  padding: 0.5em 0;
  margin-left: 1em;
}

p.character-title {
  margin-left: 1em;
}

.container {
  max-width: 70vw;
  position: relative;

  margin: 0 auto;
}

.Episode.individual,
.Character.individual,
.individual {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: var(--bb-bg);
  max-width: 60vw;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
}

.individual img {
  width: auto;
  height: 40vh;

  margin: 0 auto;
}

img {
  width: 50%;
  height: auto;
  margin: 0 auto;
  object-fit: fill;

  cursor: pointer;
}
